import { useStaticQuery, graphql } from 'gatsby';
export const useFontsDescr = () => {
  const { allGraveyardJson } = useStaticQuery(
    graphql`
      query AllFonts {
        allGraveyardJson {
          nodes {
            id
            name
            description
          }
        }
      }
    `,
  );
  return allGraveyardJson;
};
