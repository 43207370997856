import React from 'react';
import { FontCard } from './FontCard';

export const FontGrid = ({
  list,
  original,
}: {
  list: any;
  original: string;
}) => (
  <div className="grid gap-30px lg:grid-cols-2">
    {list &&
      list.map((item) => (
        <FontCard
          text={item.text}
          original={original}
          fontName={item.name}
          fontId={item.id}
        />
      ))}
  </div>
);
