function push(e, data) {
  return (
    '\ud83c\udf85 ' +
    callback(data[Math.floor(Math.random() * data.length)], e) +
    ' \ud83c\udf85'
  );
}
/**
 * @param {string} height
 * @return {?}
 */
function processdirectorrule(height) {
  return '\ud80c\udda9' + callback(CHAR_MAP, height) + '\ud80c\uddaa';
}
/**
 * @param {string} selector
 * @return {?}
 */
function $(selector) {
  return '\ua9c1\u0f3a' + selector + ' \u0f3b\ua9c2';
}
/**
 * @param {string} height
 * @return {?}
 */
function parse_topmembers(height) {
  return '\ua9c1\u0f3a ' + callback(B, height) + ' \u0f3b\ua9c2';
}
/**
 * @param {string} expr
 * @return {?}
 */
function $$(expr) {
  return (
    '\u2606\ua9c1\u272c\u25e6\u00b0\u02da\u00b0\u25e6. ' +
    callback(ch, expr) +
    ' .\u25e6\u00b0\u02da\u00b0\u25e6\u272c\ua9c2\u2606'
  );
}
/**
 * @param {string} name
 * @return {?}
 */
function nodeArray(name) {
  return callback(uniforms, name) + '\ud83c\udf08\u2122';
}
/**
 * @param {string} query
 * @return {?}
 */
function processlocationtitle(query) {
  return (
    '\u2584\ufe3b\u30c7' +
    callback(CHAR_MAP, query) +
    '\u2550\u2550\u2501\u4e00'
  );
}
/**
 * @param {string} text
 * @return {?}
 */
function template(text) {
  return text.split('').join('\u0489') + '\u0489';
}
/**
 * @param {string} name
 * @return {?}
 */
function injectDirectionProxy(name) {
  return name.split('').join('\u033e') + '\u033e';
}
/**
 * @param {string} formatted
 * @return {?}
 */
function build(formatted) {
  return formatted.split('').join('\u034e') + '\u034e';
}
/**
 * @param {string} template
 * @return {?}
 */
function re(template) {
  return template.split('').join('\u033d\u0353') + '\u033d\u0353';
}
/**
 * @param {string} text
 * @return {?}
 */
function selector(text) {
  return text.split('').join('\u035b') + '\u035b';
}
/**
 * @param {string} url
 * @return {?}
 */
function unescapeTemplateVars(url) {
  return url.replace(/([^\s])/g, '$1\u030a\u0324');
}
/**
 * @param {string} field
 * @return {?}
 */
function getWatchExpression(field) {
  return field.split('').join('\u0359') + '\u0359';
}
/**
 * @param {string} value
 * @return {?}
 */
function _normaliseClassName(value) {
  return value.replace(/([^\s])/g, '[$1\u0305\u0332]');
}
/**
 * @param {string} string
 * @return {?}
 */
function showSpacesOnString(string) {
  return string.replace(/([^\s])/g, '\u27e6$1\u27e7');
}
/**
 * @param {string} str
 * @return {?}
 */
function decodeSdpFileName(str) {
  return str.replace(/([^\s])/g, '\u2991$1\u2992');
}
/**
 * @param {string} query
 * @return {?}
 */
function escapeSearchQuery(query) {
  return query.replace(/([^\s])/g, '\ua70d$1\ua709');
}
/**
 * @param {string} mask
 * @return {?}
 */
function convertMask(mask) {
  return mask.replace(/([^\s])/g, '\ufd3e$1\u0324\u0308\ufd3f');
}
/**
 * @param {string} n
 * @return {?}
 */
function fixHeaderName(n) {
  return n.replace(/([^\s])/g, '\u29fc$1\u033c\u29fd');
}
/**
 * @param {string} str
 * @return {?}
 */
function mergeStars(str) {
  return str.replace(/([^\s])/g, '\u298f$1\u0302\u298e');
}

var lookupTable = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  a: '\u15e9',
  b: '\u15f7',
  c: '\u1462',
  d: '\u1572',
  e: '\u163f',
  f: '\u15b4',
  g: '\u161c',
  h: '\u157c',
  i: '\u14f0',
  j: '\u149a',
  k: '\u15bd\u1438',
  l: '\u14aa',
  m: '\u163b',
  n: '\u1609',
  o: '\u14cd',
  p: '\u1575',
  q: '\u1574',
  r: '\u1587',
  s: 'S',
  t: '\u15b6',
  u: '\u1458',
  v: '\u143a',
  w: '\u163a',
  x: '\u166d',
  y: '\u15bb',
  z: '\u15f1',
  A: '\u15e9',
  B: '\u15f7',
  C: '\u1462',
  D: '\u1572',
  E: '\u163f',
  F: '\u15b4',
  G: '\u161c',
  H: '\u157c',
  I: '\u14f0',
  J: '\u149a',
  K: '\u15bd\u1438',
  L: '\u14aa',
  M: '\u163b',
  N: '\u1609',
  O: '\u14cd',
  P: '\u1575',
  Q: '\u1574',
  R: '\u1587',
  S: 'S',
  T: '\u15b6',
  U: '\u1458',
  V: '\u143a',
  W: '\u163a',
  X: '\u166d',
  Y: '\u15bb',
  Z: '\u15f1',
};
var L = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  a: '\u0e04',
  b: '\u0e56',
  c: '\u00a2',
  d: '\u0ed3',
  e: '\u0113',
  f: 'f',
  g: '\u0e87',
  h: 'h',
  i: 'i',
  j: '\u0e27',
  k: 'k',
  l: 'l',
  m: '\u0e53',
  n: '\u0e96',
  o: '\u0ed0',
  p: 'p',
  q: '\u0e51',
  r: 'r',
  s: '\u015e',
  t: 't',
  u: '\u0e19',
  v: '\u0e07',
  w: '\u0e9f',
  x: 'x',
  y: '\u0e2f',
  z: '\u0e8a',
  A: '\u0e04',
  B: '\u0e56',
  C: '\u00a2',
  D: '\u0ed3',
  E: '\u0113',
  F: 'f',
  G: '\u0e87',
  H: 'h',
  I: 'i',
  J: '\u0e27',
  K: 'k',
  L: 'l',
  M: '\u0e53',
  N: '\u0e96',
  O: '\u0ed0',
  P: 'p',
  Q: '\u0e51',
  R: 'r',
  S: '\u015e',
  T: 't',
  U: '\u0e19',
  V: '\u0e07',
  W: '\u0e9f',
  X: 'x',
  Y: '\u0e2f',
  Z: '\u0e8a',
};
var conversion = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  a: '\u0105',
  b: '\u10ea',
  c: '\u0188',
  d: '\u0256',
  e: '\u025b',
  f: '\u0284',
  g: '\u0260',
  h: '\u0267',
  i: '\u0131',
  j: '\u029d',
  k: '\u0199',
  l: '\u0196',
  m: '\u0271',
  n: '\u014b',
  o: '\u01a1',
  p: '\u2118',
  q: '\u0566',
  r: '\u0f5e',
  s: '\u0282',
  t: '\u026c',
  u: '\u0173',
  v: '\u06f7',
  w: '\u1ff3',
  x: '\u04b3',
  y: '\u10e7',
  z: '\u0291',
  A: '\u0105',
  B: '\u10ea',
  C: '\u0188',
  D: '\u0256',
  E: '\u025b',
  F: '\u0284',
  G: '\u0260',
  H: '\u0267',
  I: '\u0131',
  J: '\u029d',
  K: '\u0199',
  L: '\u0196',
  M: '\u0271',
  N: '\u014b',
  O: '\u01a1',
  P: '\u2118',
  Q: '\u0566',
  R: '\u0f5e',
  S: '\u0282',
  T: '\u026c',
  U: '\u0173',
  V: '\u06f7',
  W: '\u1ff3',
  X: '\u04b3',
  Y: '\u10e7',
  Z: '\u0291',
};
var a = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  a: '\u13d7',
  b: '\u13f0',
  c: '\u1348',
  d: '\u13b4',
  e: '\u13cb',
  f: '\u13a6',
  g: '\u13b6',
  h: '\u13c2',
  i: '\u13a5',
  j: '\u13e0',
  k: '\u13e6',
  l: '\u13dd',
  m: '\u13b7',
  n: '\u13c1',
  o: '\u13a7',
  p: '\u13ae',
  q: '\u13a4',
  r: '\u13d2',
  s: '\u13d5',
  t: '\u13d6',
  u: '\u13ec',
  v: '\u13c9',
  w: '\u13c7',
  x: '\u1300',
  y: '\u13a9',
  z: '\u135a',
  A: '\u13d7',
  B: '\u13f0',
  C: '\u1348',
  D: '\u13b4',
  E: '\u13cb',
  F: '\u13a6',
  G: '\u13b6',
  H: '\u13c2',
  I: '\u13a5',
  J: '\u13e0',
  K: '\u13e6',
  L: '\u13dd',
  M: '\u13b7',
  N: '\u13c1',
  O: '\u13a7',
  P: '\u13ae',
  Q: '\u13a4',
  R: '\u13d2',
  S: '\u13d5',
  T: '\u13d6',
  U: '\u13ec',
  V: '\u13c9',
  W: '\u13c7',
  X: '\u1300',
  Y: '\u13a9',
  Z: '\u135a',
};
var good = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  a: '\u15e9',
  b: '\u15f7',
  c: '\u1455',
  d: '\u15ea',
  e: 'E',
  f: '\u15b4',
  g: 'G',
  h: '\u157c',
  i: 'I',
  j: '\u148d',
  k: 'K',
  l: '\u14aa',
  m: '\u15f0',
  n: '\u144e',
  o: 'O',
  p: '\u146d',
  q: '\u146b',
  r: '\u1587',
  s: '\u1515',
  t: 'T',
  u: '\u144c',
  v: '\u142f',
  w: '\u15ef',
  x: '\u166d',
  y: 'Y',
  z: '\u1614',
  A: '\u15e9',
  B: '\u15f7',
  C: '\u1455',
  D: '\u15ea',
  E: 'E',
  F: '\u15b4',
  G: 'G',
  H: '\u157c',
  I: 'I',
  J: '\u148d',
  K: 'K',
  L: '\u14aa',
  M: '\u15f0',
  N: '\u144e',
  O: 'O',
  P: '\u146d',
  Q: '\u146b',
  R: '\u1587',
  S: '\u1515',
  T: 'T',
  U: '\u144c',
  V: '\u142f',
  W: '\u15ef',
  X: '\u166d',
  Y: 'Y',
  Z: '\u1614',
};
var ch = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  a: '\u01df',
  b: '\u026e',
  c: '\u0188',
  d: '\u0256',
  e: '\u025b',
  f: '\u0284',
  g: '\u0262',
  h: '\u0266',
  i: '\u0268',
  j: '\u029d',
  k: '\u04c4',
  l: '\u029f',
  m: '\u028d',
  n: '\u057c',
  o: '\u0585',
  p: '\u0584',
  q: '\u0566',
  r: '\u0280',
  s: '\u0586',
  t: '\u0236',
  u: '\u028a',
  v: '\u028b',
  w: '\u0561',
  x: '\u04fc',
  y: '\u028f',
  z: '\u0290',
  A: '\u01df',
  B: '\u026e',
  C: '\u0188',
  D: '\u0256',
  E: '\u025b',
  F: '\u0284',
  G: '\u0262',
  H: '\u0266',
  I: '\u0268',
  J: '\u029d',
  K: '\u04c4',
  L: '\u029f',
  M: '\u028d',
  N: '\u057c',
  O: '\u0585',
  P: '\u0584',
  Q: '\u0566',
  R: '\u0280',
  S: '\u0586',
  T: '\u0236',
  U: '\u028a',
  V: '\u028b',
  W: '\u0561',
  X: '\u04fc',
  Y: '\u028f',
  Z: '\u0290',
};
var db = {
  0: '\ud835\udff6',
  1: '\ud835\udff7',
  2: '\ud835\udff8',
  3: '\ud835\udff9',
  4: '\ud835\udffa',
  5: '\ud835\udffb',
  6: '\ud835\udffc',
  7: '\ud835\udffd',
  8: '\ud835\udffe',
  9: '\ud835\udfff',
  a: '\ud835\ude8a',
  b: '\ud835\ude8b',
  c: '\ud835\ude8c',
  d: '\ud835\ude8d',
  e: '\ud835\ude8e',
  f: '\ud835\ude8f',
  g: '\ud835\ude90',
  h: '\ud835\ude91',
  i: '\ud835\ude92',
  j: '\ud835\ude93',
  k: '\ud835\ude94',
  l: '\ud835\ude95',
  m: '\ud835\ude96',
  n: '\ud835\ude97',
  o: '\ud835\ude98',
  p: '\ud835\ude99',
  q: '\ud835\ude9a',
  r: '\ud835\ude9b',
  s: '\ud835\ude9c',
  t: '\ud835\ude9d',
  u: '\ud835\ude9e',
  v: '\ud835\ude9f',
  w: '\ud835\udea0',
  x: '\ud835\udea1',
  y: '\ud835\udea2',
  z: '\ud835\udea3',
  A: '\ud835\ude70',
  B: '\ud835\ude71',
  C: '\ud835\ude72',
  D: '\ud835\ude73',
  E: '\ud835\ude74',
  F: '\ud835\ude75',
  G: '\ud835\ude76',
  H: '\ud835\ude77',
  I: '\ud835\ude78',
  J: '\ud835\ude79',
  K: '\ud835\ude7a',
  L: '\ud835\ude7b',
  M: '\ud835\ude7c',
  N: '\ud835\ude7d',
  O: '\ud835\ude7e',
  P: '\ud835\ude7f',
  Q: '\ud835\ude80',
  R: '\ud835\ude81',
  S: '\ud835\ude82',
  T: '\ud835\ude83',
  U: '\ud835\ude84',
  V: '\ud835\ude85',
  W: '\ud835\ude86',
  X: '\ud835\ude87',
  Y: '\ud835\ude88',
  Z: '\ud835\ude89',
};
var patterns = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  a: '\ud835\ude56',
  b: '\ud835\ude57',
  c: '\ud835\ude58',
  d: '\ud835\ude59',
  e: '\ud835\ude5a',
  f: '\ud835\ude5b',
  g: '\ud835\ude5c',
  h: '\ud835\ude5d',
  i: '\ud835\ude5e',
  j: '\ud835\ude5f',
  k: '\ud835\ude60',
  l: '\ud835\ude61',
  m: '\ud835\ude62',
  n: '\ud835\ude63',
  o: '\ud835\ude64',
  p: '\ud835\ude65',
  q: '\ud835\ude66',
  r: '\ud835\ude67',
  s: '\ud835\ude68',
  t: '\ud835\ude69',
  u: '\ud835\ude6a',
  v: '\ud835\ude6b',
  w: '\ud835\ude6c',
  x: '\ud835\ude6d',
  y: '\ud835\ude6e',
  z: '\ud835\ude6f',
  A: '\ud835\ude3c',
  B: '\ud835\ude3d',
  C: '\ud835\ude3e',
  D: '\ud835\ude3f',
  E: '\ud835\ude40',
  F: '\ud835\ude41',
  G: '\ud835\ude42',
  H: '\ud835\ude43',
  I: '\ud835\ude44',
  J: '\ud835\ude45',
  K: '\ud835\ude46',
  L: '\ud835\ude47',
  M: '\ud835\ude48',
  N: '\ud835\ude49',
  O: '\ud835\ude4a',
  P: '\ud835\ude4b',
  Q: '\ud835\ude4c',
  R: '\ud835\ude4d',
  S: '\ud835\ude4e',
  T: '\ud835\ude4f',
  U: '\ud835\ude50',
  V: '\ud835\ude51',
  W: '\ud835\ude52',
  X: '\ud835\ude53',
  Y: '\ud835\ude54',
  Z: '\ud835\ude55',
};
var uniforms = {
  0: '\ud835\udfce',
  1: '\ud835\udfcf',
  2: '\ud835\udfd0',
  3: '\ud835\udfd1',
  4: '\ud835\udfd2',
  5: '\ud835\udfd3',
  6: '\ud835\udfd4',
  7: '\ud835\udfd5',
  8: '\ud835\udfd6',
  9: '\ud835\udfd7',
  a: '\ud835\udc1a',
  b: '\ud835\udc1b',
  c: '\ud835\udc1c',
  d: '\ud835\udc1d',
  e: '\ud835\udc1e',
  f: '\ud835\udc1f',
  g: '\ud835\udc20',
  h: '\ud835\udc21',
  i: '\ud835\udc22',
  j: '\ud835\udc23',
  k: '\ud835\udc24',
  l: '\ud835\udc25',
  m: '\ud835\udc26',
  n: '\ud835\udc27',
  o: '\ud835\udc28',
  p: '\ud835\udc29',
  q: '\ud835\udc2a',
  r: '\ud835\udc2b',
  s: '\ud835\udc2c',
  t: '\ud835\udc2d',
  u: '\ud835\udc2e',
  v: '\ud835\udc2f',
  w: '\ud835\udc30',
  x: '\ud835\udc31',
  y: '\ud835\udc32',
  z: '\ud835\udc33',
  A: '\ud835\udc00',
  B: '\ud835\udc01',
  C: '\ud835\udc02',
  D: '\ud835\udc03',
  E: '\ud835\udc04',
  F: '\ud835\udc05',
  G: '\ud835\udc06',
  H: '\ud835\udc07',
  I: '\ud835\udc08',
  J: '\ud835\udc09',
  K: '\ud835\udc0a',
  L: '\ud835\udc0b',
  M: '\ud835\udc0c',
  N: '\ud835\udc0d',
  O: '\ud835\udc0e',
  P: '\ud835\udc0f',
  Q: '\ud835\udc10',
  R: '\ud835\udc11',
  S: '\ud835\udc12',
  T: '\ud835\udc13',
  U: '\ud835\udc14',
  V: '\ud835\udc15',
  W: '\ud835\udc16',
  X: '\ud835\udc17',
  Y: '\ud835\udc18',
  Z: '\ud835\udc19',
};
var res = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  a: '\ud835\ude22',
  b: '\ud835\ude23',
  c: '\ud835\ude24',
  d: '\ud835\ude25',
  e: '\ud835\ude26',
  f: '\ud835\ude27',
  g: '\ud835\ude28',
  h: '\ud835\ude29',
  i: '\ud835\ude2a',
  j: '\ud835\ude2b',
  k: '\ud835\ude2c',
  l: '\ud835\ude2d',
  m: '\ud835\ude2e',
  n: '\ud835\ude2f',
  o: '\ud835\ude30',
  p: '\ud835\ude31',
  q: '\ud835\ude32',
  r: '\ud835\ude33',
  s: '\ud835\ude34',
  t: '\ud835\ude35',
  u: '\ud835\ude36',
  v: '\ud835\ude37',
  w: '\ud835\ude38',
  x: '\ud835\ude39',
  y: '\ud835\ude3a',
  z: '\ud835\ude3b',
  A: '\ud835\ude08',
  B: '\ud835\ude09',
  C: '\ud835\ude0a',
  D: '\ud835\ude0b',
  E: '\ud835\ude0c',
  F: '\ud835\ude0d',
  G: '\ud835\ude0e',
  H: '\ud835\ude0f',
  I: '\ud835\ude10',
  J: '\ud835\ude11',
  K: '\ud835\ude12',
  L: '\ud835\ude13',
  M: '\ud835\ude14',
  N: '\ud835\ude15',
  O: '\ud835\ude16',
  P: '\ud835\ude17',
  Q: '\ud835\ude18',
  R: '\ud835\ude19',
  S: '\ud835\ude1a',
  T: '\ud835\ude1b',
  U: '\ud835\ude1c',
  V: '\ud835\ude1d',
  W: '\ud835\ude1e',
  X: '\ud835\ude1f',
  Y: '\ud835\ude20',
  Z: '\ud835\ude21',
};
var b = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  a: '\u03b1',
  b: '\u10a6',
  c: '\u0188',
  d: '\u0503',
  e: '\u04bd',
  f: '\u03dd',
  g: '\u0260',
  h: '\u050b',
  i: '\u03b9',
  j: '\u029d',
  k: '\u0199',
  l: '\u0285',
  m: '\u0271',
  n: '\u0273',
  o: '\u03c3',
  p: '\u03c1',
  q: '\u03d9',
  r: '\u027e',
  s: '\u0282',
  t: '\u019a',
  u: '\u03c5',
  v: '\u028b',
  w: '\u026f',
  x: 'x',
  y: '\u10e7',
  z: '\u0225',
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G',
  H: 'H',
  I: 'I',
  J: 'J',
  K: 'K',
  L: 'L',
  M: 'M',
  N: 'N',
  O: 'O',
  P: 'P',
  Q: 'Q',
  R: 'R',
  S: 'S',
  T: 'T',
  U: 'U',
  V: 'V',
  W: 'W',
  X: 'X',
  Y: 'Y',
  Z: 'Z',
};
var oneToThree = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  a: '\u03b1',
  b: '\u0432',
  c: '\u00a2',
  d: '\u2202',
  e: '\u0454',
  f: '\u0192',
  g: 'g',
  h: '\u043d',
  i: '\u03b9',
  j: '\u05e0',
  k: '\u043a',
  l: '\u2113',
  m: '\u043c',
  n: '\u03b7',
  o: '\u03c3',
  p: '\u03c1',
  q: 'q',
  r: '\u044f',
  s: '\u0455',
  t: '\u0442',
  u: '\u03c5',
  v: '\u03bd',
  w: '\u03c9',
  x: '\u03c7',
  y: '\u0443',
  z: 'z',
  A: '\u03b1',
  B: '\u0432',
  C: '\u00a2',
  D: '\u2202',
  E: '\u0454',
  F: '\u0192',
  G: 'g',
  H: '\u043d',
  I: '\u03b9',
  J: '\u05e0',
  K: '\u043a',
  L: '\u2113',
  M: '\u043c',
  N: '\u03b7',
  O: '\u03c3',
  P: '\u03c1',
  Q: 'q',
  R: '\u044f',
  S: '\u0455',
  T: '\u0442',
  U: '\u03c5',
  V: '\u03bd',
  W: '\u03c9',
  X: '\u03c7',
  Y: '\u0443',
  Z: 'z',
};
var regExpMap = {
  0: '\u2298',
  1: '\ud835\udfd9',
  2: '\u03e9',
  3: '\u04e0',
  4: '\u096b',
  5: '\u01bc',
  6: '\u03ec',
  7: '7',
  8: '\ud835\udfe0',
  9: '\u096f',
  a: '\u0105',
  b: '\u048d',
  c: '\u00e7',
  d: '\u056a',
  e: '\u04bd',
  f: '\u0192',
  g: '\u0581',
  h: '\u0570',
  i: '\u00ec',
  j: '\u029d',
  k: '\u049f',
  l: '\u04c0',
  m: '\u028d',
  n: '\u0572',
  o: '\u0585',
  p: '\u0584',
  q: '\u0566',
  r: '\u027e',
  s: '\u0282',
  t: '\u0567',
  u: '\u0574',
  v: '\u0475',
  w: '\u0561',
  x: '\u00d7',
  y: '\u057e',
  z: '\u0540',
  A: '\u023a',
  B: '\u03b2',
  C: '\u21bb',
  D: '\u13a0',
  E: '\u0190',
  F: '\u0191',
  G: '\u0193',
  H: '\u01f6',
  I: '\u012f',
  J: '\u0644',
  K: '\u04a0',
  L: '\ua748',
  M: '\u2c6e',
  N: '\u17a0',
  O: '\u0da7',
  P: '\u03c6',
  Q: '\u04a8',
  R: '\u0f60',
  S: '\u03da',
  T: '\u0372',
  U: '\u0531',
  V: '\u1efc',
  W: '\u0c1a',
  X: '\u10ef',
  Y: '\u04cb',
  Z: '\u0240',
};
var colors = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  a: '\u039b',
  b: 'B',
  c: '\u1103',
  d: 'D',
  e: '\u03a3',
  f: 'F',
  g: 'G',
  h: '\u0389',
  i: 'I',
  j: 'J',
  k: 'K',
  l: '\u1102',
  m: 'M',
  n: '\u041f',
  o: '\u04e8',
  p: 'P',
  q: 'Q',
  r: '\u042f',
  s: '\u01a7',
  t: '\u01ac',
  u: '\u0426',
  v: 'V',
  w: '\u0429',
  x: 'X',
  y: 'Y',
  z: 'Z',
  A: '\u039b',
  B: 'B',
  C: '\u1103',
  D: 'D',
  E: '\u03a3',
  F: 'F',
  G: 'G',
  H: '\u0389',
  I: 'I',
  J: 'J',
  K: 'K',
  L: '\u1102',
  M: 'M',
  N: '\u041f',
  O: '\u04e8',
  P: 'P',
  Q: 'Q',
  R: '\u042f',
  S: '\u01a7',
  T: '\u01ac',
  U: '\u0426',
  V: 'V',
  W: '\u0429',
  X: 'X',
  Y: 'Y',
  Z: 'Z',
};
var HEX_NUMBER_CHARS = {
  0: '\u2080',
  1: '\u2081',
  2: '\u2082',
  3: '\u2083',
  4: '\u2084',
  5: '\u2085',
  6: '\u2086',
  7: '\u2087',
  8: '\u2088',
  9: '\u2089',
  a: '\u2090',
  b: '\ud835\udc4f',
  c: '\ud835\udc50',
  d: '\ud835\udc51',
  e: '\u2091',
  f: '\ud835\udc53',
  g: '\ud835\udc54',
  h: '\u2c8f',
  i: '\u1d62',
  j: '\u2c7c',
  k: '\u2c95',
  l: '\u053c',
  m: '\ud835\udc5a',
  n: '\ud835\udc5b',
  o: '\u2092',
  p: '\ud835\udc91',
  q: '\ud835\udc92',
  r: '\u1d63',
  s: '\ud835\udc60',
  t: '\ud835\udc61',
  u: '\u1d64',
  v: '\u1d65',
  w: '\uff57',
  x: '\u2093',
  y: '\ud835\udc66',
  z: '\ud835\udc67',
  A: '\u2090',
  B: '\ud835\udc4f',
  C: '\ud835\udc50',
  D: '\ud835\udc51',
  E: '\u2091',
  F: '\ud835\udc53',
  G: '\ud835\udc54',
  H: '\u2c8f',
  I: '\u1d62',
  J: '\u2c7c',
  K: '\u2c95',
  L: '\u053c',
  M: '\u17f3',
  N: '\u17f7',
  O: '\u2092',
  P: '\uff30',
  Q: '\uff31',
  R: '\u1d63',
  S: '\ua731',
  T: '\u0442',
  U: '\u1d64',
  V: '\u1d65',
  W: '\uff57',
  X: '\u2093',
  Y: '\ud835\udc66',
  Z: '\ud835\udc67',
  '+': '\u208a',
  '-': '\u208b',
  '=': '\u208c',
  '(': '\u208d',
  ')': '\u208e',
};
var _PATTERNS = {
  0: '\u2070',
  1: '\u00b9',
  2: '\u00b2',
  3: '\u00b3',
  4: '\u2074',
  5: '\u2075',
  6: '\u2076',
  7: '\u2077',
  8: '\u2078',
  9: '\u2079',
  a: '\u1d43',
  b: '\u1d47',
  c: '\u1d9c',
  d: '\u1d48',
  e: '\u1d49',
  f: '\u1da0',
  g: '\u1d4d',
  h: '\u02b0',
  i: '\u2071',
  j: '\u02b2',
  k: '\u1d4f',
  l: '\u02e1',
  m: '\u1d50',
  n: '\u207f',
  o: '\u1d52',
  p: '\u1d56',
  q: 'q',
  r: '\u02b3',
  s: '\u02e2',
  t: '\u1d57',
  u: '\u1d58',
  v: '\u1d5b',
  w: '\u02b7',
  x: '\u02e3',
  y: '\u02b8',
  z: '\u1dbb',
  A: '\u1d2c',
  B: '\u1d2e',
  C: '\u1d9c',
  D: '\u1d30',
  E: '\u1d31',
  F: '\u1da0',
  G: '\u1d33',
  H: '\u1d34',
  I: '\u1d35',
  J: '\u1d36',
  K: '\u1d37',
  L: '\u1d38',
  M: '\u1d39',
  N: '\u1d3a',
  O: '\u1d3c',
  P: '\u1d3e',
  Q: 'Q',
  R: '\u1d3f',
  S: '\u02e2',
  T: '\u1d40',
  U: '\u1d41',
  V: '\u2c7d',
  W: '\u1d42',
  X: '\u02e3',
  Y: '\u02b8',
  Z: '\u1dbb',
  '+': '\u207a',
  '-': '\u207b',
  '=': '\u207c',
  '(': '\u207d',
  ')': '\u207e',
};
var error = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  a: '\u0e04',
  b: '\u0e52',
  c: '\u03c2',
  d: '\u0e54',
  e: '\u0454',
  f: '\u0166',
  g: '\ufeee',
  h: '\u0452',
  i: '\u0e40',
  j: '\u05df',
  k: '\u043a',
  l: '\u026d',
  m: '\u0e53',
  n: '\u0e20',
  o: '\u0e4f',
  p: '\u05e7',
  q: '\u1ee3',
  r: '\u0433',
  s: '\u0e23',
  t: '\u0547',
  u: '\u0e22',
  v: '\u05e9',
  w: '\u0e2c',
  x: '\u05d0',
  y: '\u05e5',
  z: '\u0579',
  A: '\u0e04',
  B: '\u0e52',
  C: '\u03c2',
  D: '\u0e54',
  E: '\u0454',
  F: '\u0166',
  G: '\ufeee',
  H: '\u0452',
  I: '\u0e40',
  J: '\u05df',
  K: '\u043a',
  L: '\u026d',
  M: '\u0e53',
  N: '\u0e20',
  O: '\u0e4f',
  P: '\u05e7',
  Q: '\u1ee3',
  R: '\u0433',
  S: '\u0e23',
  T: '\u0547',
  U: '\u0e22',
  V: '\u05e9',
  W: '\u0e2c',
  X: '\u05d0',
  Y: '\u05e5',
  Z: '\u0579',
};
var lookup = {
  0: '\ud835\udfd8',
  1: '\ud835\udfd9',
  2: '\ud835\udfda',
  3: '\ud835\udfdb',
  4: '\ud835\udfdc',
  5: '\ud835\udfdd',
  6: '\ud835\udfde',
  7: '\ud835\udfdf',
  8: '\ud835\udfe0',
  9: '\ud835\udfe1',
  a: '\ud835\udd52',
  b: '\ud835\udd53',
  c: '\ud835\udd54',
  d: '\ud835\udd55',
  e: '\ud835\udd56',
  f: '\ud835\udd57',
  g: '\ud835\udd58',
  h: '\ud835\udd59',
  i: '\ud835\udd5a',
  j: '\ud835\udd5b',
  k: '\ud835\udd5c',
  l: '\ud835\udd5d',
  m: '\ud835\udd5e',
  n: '\ud835\udd5f',
  o: '\ud835\udd60',
  p: '\ud835\udd61',
  q: '\ud835\udd62',
  r: '\ud835\udd63',
  s: '\ud835\udd64',
  t: '\ud835\udd65',
  u: '\ud835\udd66',
  v: '\ud835\udd67',
  w: '\ud835\udd68',
  x: '\ud835\udd69',
  y: '\ud835\udd6a',
  z: '\ud835\udd6b',
  A: '\ud835\udd38',
  B: '\ud835\udd39',
  C: '\u2102',
  D: '\ud835\udd3b',
  E: '\ud835\udd3c',
  F: '\ud835\udd3d',
  G: '\ud835\udd3e',
  H: '\u210d',
  I: '\ud835\udd40',
  J: '\ud835\udd41',
  K: '\ud835\udd42',
  L: '\ud835\udd43',
  M: '\ud835\udd44',
  N: '\u2115',
  O: '\ud835\udd46',
  P: '\u2119',
  Q: '\u211a',
  R: '\u211d',
  S: '\ud835\udd4a',
  T: '\ud835\udd4b',
  U: '\ud835\udd4c',
  V: '\ud835\udd4d',
  W: '\ud835\udd4e',
  X: '\ud835\udd4f',
  Y: '\ud835\udd50',
  Z: '\u2124',
};
var E = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  a: '\ud835\udd86',
  b: '\ud835\udd87',
  c: '\ud835\udd88',
  d: '\ud835\udd89',
  e: '\ud835\udd8a',
  f: '\ud835\udd8b',
  g: '\ud835\udd8c',
  h: '\ud835\udd8d',
  i: '\ud835\udd8e',
  j: '\ud835\udd8f',
  k: '\ud835\udd90',
  l: '\ud835\udd91',
  m: '\ud835\udd92',
  n: '\ud835\udd93',
  o: '\ud835\udd94',
  p: '\ud835\udd95',
  q: '\ud835\udd96',
  r: '\ud835\udd97',
  s: '\ud835\udd98',
  t: '\ud835\udd99',
  u: '\ud835\udd9a',
  v: '\ud835\udd9b',
  w: '\ud835\udd9c',
  x: '\ud835\udd9d',
  y: '\ud835\udd9e',
  z: '\ud835\udd9f',
  A: '\ud835\udd6c',
  B: '\ud835\udd6d',
  C: '\ud835\udd6e',
  D: '\ud835\udd6f',
  E: '\ud835\udd70',
  F: '\ud835\udd71',
  G: '\ud835\udd72',
  H: '\ud835\udd73',
  I: '\ud835\udd74',
  J: '\ud835\udd75',
  K: '\ud835\udd76',
  L: '\ud835\udd77',
  M: '\ud835\udd78',
  N: '\ud835\udd79',
  O: '\ud835\udd7a',
  P: '\ud835\udd7b',
  Q: '\ud835\udd7c',
  R: '\ud835\udd7d',
  S: '\ud835\udd7e',
  T: '\ud835\udd7f',
  U: '\ud835\udd80',
  V: '\ud835\udd81',
  W: '\ud835\udd82',
  X: '\ud835\udd83',
  Y: '\ud835\udd84',
  Z: '\ud835\udd85',
};
var cached_wifi_network_list = {
  0: '0',
  ' ': ' ',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  a: '\ud83c\udd30',
  b: '\ud83c\udd31',
  c: '\ud83c\udd32',
  d: '\ud83c\udd33',
  e: '\ud83c\udd34',
  f: '\ud83c\udd35',
  g: '\ud83c\udd36',
  h: '\ud83c\udd37',
  i: '\ud83c\udd38',
  j: '\ud83c\udd39',
  k: '\ud83c\udd3a',
  l: '\ud83c\udd3b',
  m: '\ud83c\udd3c',
  n: '\ud83c\udd3d',
  o: '\ud83c\udd3e',
  p: '\ud83c\udd3f',
  q: '\ud83c\udd40',
  r: '\ud83c\udd41',
  s: '\ud83c\udd42',
  t: '\ud83c\udd43',
  u: '\ud83c\udd44',
  v: '\ud83c\udd45',
  w: '\ud83c\udd46',
  x: '\ud83c\udd47',
  y: '\ud83c\udd48',
  z: '\ud83c\udd49',
  A: '\ud83c\udd30',
  B: '\ud83c\udd31',
  C: '\ud83c\udd32',
  D: '\ud83c\udd33',
  E: '\ud83c\udd34',
  F: '\ud83c\udd35',
  G: '\ud83c\udd36',
  H: '\ud83c\udd37',
  I: '\ud83c\udd38',
  J: '\ud83c\udd39',
  K: '\ud83c\udd3a',
  L: '\ud83c\udd3b',
  M: '\ud83c\udd3c',
  N: '\ud83c\udd3d',
  O: '\ud83c\udd3e',
  P: '\ud83c\udd3f',
  Q: '\ud83c\udd40',
  R: '\ud83c\udd41',
  S: '\ud83c\udd42',
  T: '\ud83c\udd43',
  U: '\ud83c\udd44',
  V: '\ud83c\udd45',
  W: '\ud83c\udd46',
  X: '\ud83c\udd47',
  Y: '\ud83c\udd48',
  Z: '\ud83c\udd49',
};
var setFnMap = {
  0: '\u24ff',
  ' ': ' ',
  1: '\u278a',
  2: '\u278b',
  3: '\u278c',
  4: '\u278d',
  5: '\u278e',
  6: '\u278f',
  7: '\u2790',
  8: '\u2791',
  9: '\u2792',
  a: '\ud83c\udd50',
  b: '\ud83c\udd51',
  c: '\ud83c\udd52',
  d: '\ud83c\udd53',
  e: '\ud83c\udd54',
  f: '\ud83c\udd55',
  g: '\ud83c\udd56',
  h: '\ud83c\udd57',
  i: '\ud83c\udd58',
  j: '\ud83c\udd59',
  k: '\ud83c\udd5a',
  l: '\ud83c\udd5b',
  m: '\ud83c\udd5c',
  n: '\ud83c\udd5d',
  o: '\ud83c\udd5e',
  p: '\ud83c\udd5f',
  q: '\ud83c\udd60',
  r: '\ud83c\udd61',
  s: '\ud83c\udd62',
  t: '\ud83c\udd63',
  u: '\ud83c\udd64',
  v: '\ud83c\udd65',
  w: '\ud83c\udd66',
  x: '\ud83c\udd67',
  y: '\ud83c\udd68',
  z: '\ud83c\udd69',
  A: '\ud83c\udd50',
  B: '\ud83c\udd51',
  C: '\ud83c\udd52',
  D: '\ud83c\udd53',
  E: '\ud83c\udd54',
  F: '\ud83c\udd55',
  G: '\ud83c\udd56',
  H: '\ud83c\udd57',
  I: '\ud83c\udd58',
  J: '\ud83c\udd59',
  K: '\ud83c\udd5a',
  L: '\ud83c\udd5b',
  M: '\ud83c\udd5c',
  N: '\ud83c\udd5d',
  O: '\ud83c\udd5e',
  P: '\ud83c\udd5f',
  Q: '\ud83c\udd60',
  R: '\ud83c\udd61',
  S: '\ud83c\udd62',
  T: '\ud83c\udd63',
  U: '\ud83c\udd64',
  V: '\ud83c\udd65',
  W: '\ud83c\udd66',
  X: '\ud83c\udd67',
  Y: '\ud83c\udd68',
  Z: '\ud83c\udd69',
};
var languages = {
  0: '\u24ea',
  ' ': ' ',
  1: '\u2460',
  2: '\u2461',
  3: '\u2462',
  4: '\u2463',
  5: '\u2464',
  6: '\u2465',
  7: '\u2466',
  8: '\u2467',
  9: '\u2468',
  a: '\u24d0',
  b: '\u24d1',
  c: '\u24d2',
  d: '\u24d3',
  e: '\u24d4',
  f: '\u24d5',
  g: '\u24d6',
  h: '\u24d7',
  i: '\u24d8',
  j: '\u24d9',
  k: '\u24da',
  l: '\u24db',
  m: '\u24dc',
  n: '\u24dd',
  o: '\u24de',
  p: '\u24df',
  q: '\u24e0',
  r: '\u24e1',
  s: '\u24e2',
  t: '\u24e3',
  u: '\u24e4',
  v: '\u24e5',
  w: '\u24e6',
  x: '\u24e7',
  y: '\u24e8',
  z: '\u24e9',
  A: '\u24b6',
  B: '\u24b7',
  C: '\u24b8',
  D: '\u24b9',
  E: '\u24ba',
  F: '\u24bb',
  G: '\u24bc',
  H: '\u24bd',
  I: '\u24be',
  J: '\u24bf',
  K: '\u24c0',
  L: '\u24c1',
  M: '\u24c2',
  N: '\u24c3',
  O: '\u24c4',
  P: '\u24c5',
  Q: '\u24c6',
  R: '\u24c7',
  S: '\u24c8',
  T: '\u24c9',
  U: '\u24ca',
  V: '\u24cb',
  W: '\u24cc',
  X: '\u24cd',
  Y: '\u24ce',
  Z: '\u24cf',
};
var liveLeakUrl = {
  0: '\u24ff',
  ' ': ' ',
  1: '\u278a',
  2: '\u278b',
  3: '\u278c',
  4: '\u278d',
  5: '\u278e',
  6: '\u278f',
  7: '\u2790',
  8: '\u2791',
  9: '\u2792',
  a: '\ud83c\udd70',
  b: '\ud83c\udd71',
  c: '\ud83c\udd72',
  d: '\ud83c\udd73',
  e: '\ud83c\udd74',
  f: '\ud83c\udd75',
  g: '\ud83c\udd76',
  h: '\ud83c\udd77',
  i: '\ud83c\udd78',
  j: '\ud83c\udd79',
  k: '\ud83c\udd7a',
  l: '\ud83c\udd7b',
  m: '\ud83c\udd7c',
  n: '\ud83c\udd7d',
  o: '\ud83c\udd7e',
  p: '\ud83c\udd7f',
  q: '\ud83c\udd80',
  r: '\ud83c\udd81',
  s: '\ud83c\udd82',
  t: '\ud83c\udd83',
  u: '\ud83c\udd84',
  v: '\ud83c\udd85',
  w: '\ud83c\udd86',
  x: '\ud83c\udd87',
  y: '\ud83c\udd88',
  z: '\ud83c\udd89',
  A: '\ud83c\udd70',
  B: '\ud83c\udd71',
  C: '\ud83c\udd72',
  D: '\ud83c\udd73',
  E: '\ud83c\udd74',
  F: '\ud83c\udd75',
  G: '\ud83c\udd76',
  H: '\ud83c\udd77',
  I: '\ud83c\udd78',
  J: '\ud83c\udd79',
  K: '\ud83c\udd7a',
  L: '\ud83c\udd7b',
  M: '\ud83c\udd7c',
  N: '\ud83c\udd7d',
  O: '\ud83c\udd7e',
  P: '\ud83c\udd7f',
  Q: '\ud83c\udd80',
  R: '\ud83c\udd81',
  S: '\ud83c\udd82',
  T: '\ud83c\udd83',
  U: '\ud83c\udd84',
  V: '\ud83c\udd85',
  W: '\ud83c\udd86',
  X: '\ud83c\udd87',
  Y: '\ud83c\udd88',
  Z: '\ud83c\udd89',
};
var B = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  a: '\ud835\udcea',
  b: '\ud835\udceb',
  c: '\ud835\udcec',
  d: '\ud835\udced',
  e: '\ud835\udcee',
  f: '\ud835\udcef',
  g: '\ud835\udcf0',
  h: '\ud835\udcf1',
  i: '\ud835\udcf2',
  j: '\ud835\udcf3',
  k: '\ud835\udcf4',
  l: '\ud835\udcf5',
  m: '\ud835\udcf6',
  n: '\ud835\udcf7',
  o: '\ud835\udcf8',
  p: '\ud835\udcf9',
  q: '\ud835\udcfa',
  r: '\ud835\udcfb',
  s: '\ud835\udcfc',
  t: '\ud835\udcfd',
  u: '\ud835\udcfe',
  v: '\ud835\udcff',
  w: '\ud835\udd00',
  x: '\ud835\udd01',
  y: '\ud835\udd02',
  z: '\ud835\udd03',
  A: '\ud835\udcd0',
  B: '\ud835\udcd1',
  C: '\ud835\udcd2',
  D: '\ud835\udcd3',
  E: '\ud835\udcd4',
  F: '\ud835\udcd5',
  G: '\ud835\udcd6',
  H: '\ud835\udcd7',
  I: '\ud835\udcd8',
  J: '\ud835\udcd9',
  K: '\ud835\udcda',
  L: '\ud835\udcdb',
  M: '\ud835\udcdc',
  N: '\ud835\udcdd',
  O: '\ud835\udcde',
  P: '\ud835\udcdf',
  Q: '\ud835\udce0',
  R: '\ud835\udce1',
  S: '\ud835\udce2',
  T: '\ud835\udce3',
  U: '\ud835\udce4',
  V: '\ud835\udce5',
  W: '\ud835\udce6',
  X: '\ud835\udce7',
  Y: '\ud835\udce8',
  Z: '\ud835\udce9',
};
var map = {
  a: '\ud835\udd1e',
  b: '\ud835\udd1f',
  c: '\ud835\udd20',
  d: '\ud835\udd21',
  e: '\ud835\udd22',
  f: '\ud835\udd23',
  g: '\ud835\udd24',
  h: '\ud835\udd25',
  i: '\ud835\udd26',
  j: '\ud835\udd27',
  k: '\ud835\udd28',
  l: '\ud835\udd29',
  m: '\ud835\udd2a',
  n: '\ud835\udd2b',
  o: '\ud835\udd2c',
  p: '\ud835\udd2d',
  q: '\ud835\udd2e',
  r: '\ud835\udd2f',
  s: '\ud835\udd30',
  t: '\ud835\udd31',
  u: '\ud835\udd32',
  v: '\ud835\udd33',
  w: '\ud835\udd34',
  x: '\ud835\udd35',
  y: '\ud835\udd36',
  z: '\ud835\udd37',
  A: '\ud835\udd04',
  B: '\ud835\udd05',
  C: '\u212d',
  D: '\ud835\udd07',
  E: '\ud835\udd08',
  F: '\ud835\udd09',
  G: '\ud835\udd0a',
  H: '\u210c',
  I: '\u2111',
  J: '\ud835\udd0d',
  K: '\ud835\udd0e',
  L: '\ud835\udd0f',
  M: '\ud835\udd10',
  N: '\ud835\udd11',
  O: '\ud835\udd12',
  P: '\ud835\udd13',
  Q: '\ud835\udd14',
  R: '\u211c',
  S: '\ud835\udd16',
  T: '\ud835\udd17',
  U: '\ud835\udd18',
  V: '\ud835\udd19',
  W: '\ud835\udd1a',
  X: '\ud835\udd1b',
  Y: '\ud835\udd1c',
  Z: '\u2128',
};
var entities = {
  '`': '`',
  1: '\uff11',
  2: '\uff12',
  3: '\uff13',
  4: '\uff14',
  5: '\uff15',
  6: '\uff16',
  7: '\uff17',
  8: '\uff18',
  9: '\uff19',
  0: '\uff10',
  '-': '\uff0d',
  '=': '\uff1d',
  '~': '~',
  '!': '\uff01',
  '@': '\uff20',
  '#': '\uff03',
  $: '\uff04',
  '%': '\uff05',
  '^': '^',
  '&': '\uff06',
  '*': '\uff0a',
  '(': '\uff08',
  ')': '\uff09',
  _: '_',
  '+': '\uff0b',
  q: '\uff51',
  w: '\uff57',
  e: '\uff45',
  r: '\uff52',
  t: '\uff54',
  y: '\uff59',
  u: '\uff55',
  i: '\uff49',
  o: '\uff4f',
  p: '\uff50',
  '[': '[',
  ']': ']',
  '\\': '\\',
  Q: '\uff31',
  W: '\uff37',
  E: '\uff25',
  R: '\uff32',
  T: '\uff34',
  Y: '\uff39',
  U: '\uff35',
  I: '\uff29',
  O: '\uff2f',
  P: '\uff30',
  '{': '{',
  '}': '}',
  '|': '|',
  a: '\uff41',
  s: '\uff53',
  d: '\uff44',
  f: '\uff46',
  g: '\uff47',
  h: '\uff48',
  j: '\uff4a',
  k: '\uff4b',
  l: '\uff4c',
  ';': '\uff1b',
  "'": '\uff07',
  A: '\uff21',
  S: '\uff33',
  D: '\uff24',
  F: '\uff26',
  G: '\uff27',
  H: '\uff28',
  J: '\uff2a',
  K: '\uff2b',
  L: '\uff2c',
  ':': '\uff1a',
  '"': '"',
  z: '\uff5a',
  x: '\uff58',
  c: '\uff43',
  v: '\uff56',
  b: '\uff42',
  n: '\uff4e',
  m: '\uff4d',
  ',': '\uff0c',
  '.': '\uff0e',
  '/': '\uff0f',
  Z: '\uff3a',
  X: '\uff38',
  C: '\uff23',
  V: '\uff36',
  B: '\uff22',
  N: '\uff2e',
  M: '\uff2d',
  '<': '<',
  '>': '>',
  '?': '\uff1f',
};
var KeyCodes = {
  0: '\ud835\udfe2',
  1: '\ud835\udfe3',
  2: '\ud835\udfe4',
  3: '\ud835\udfe5',
  4: '\ud835\udfe6',
  5: '\ud835\udfe7',
  6: '\ud835\udfe8',
  7: '\ud835\udfe9',
  8: '\ud835\udfea',
  9: '\ud835\udfeb',
  a: '\ud835\udcb6',
  b: '\ud835\udcb7',
  c: '\ud835\udcb8',
  d: '\ud835\udcb9',
  e: '\ud835\udc52',
  f: '\ud835\udcbb',
  g: '\ud835\udc54',
  h: '\ud835\udcbd',
  i: '\ud835\udcbe',
  j: '\ud835\udcbf',
  k: '\ud835\udcc0',
  l: '\ud835\udcc1',
  m: '\ud835\udcc2',
  n: '\ud835\udcc3',
  o: '\ud835\udc5c',
  p: '\ud835\udcc5',
  q: '\ud835\udcc6',
  r: '\ud835\udcc7',
  s: '\ud835\udcc8',
  t: '\ud835\udcc9',
  u: '\ud835\udcca',
  v: '\ud835\udccb',
  w: '\ud835\udccc',
  x: '\ud835\udccd',
  y: '\ud835\udcce',
  z: '\ud835\udccf',
  A: '\ud835\udc9c',
  B: '\ud835\udc35',
  C: '\ud835\udc9e',
  D: '\ud835\udc9f',
  E: '\ud835\udc38',
  F: '\ud835\udc39',
  G: '\ud835\udca2',
  H: '\ud835\udc3b',
  I: '\ud835\udc3c',
  J: '\ud835\udca5',
  K: '\ud835\udca6',
  L: '\ud835\udc3f',
  M: '\ud835\udc40',
  N: '\ud835\udca9',
  O: '\ud835\udcaa',
  P: '\ud835\udcab',
  Q: '\ud835\udcac',
  R: '\ud835\udc45',
  S: '\ud835\udcae',
  T: '\ud835\udcaf',
  U: '\ud835\udcb0',
  V: '\ud835\udcb1',
  W: '\ud835\udcb2',
  X: '\ud835\udcb3',
  Y: '\ud835\udcb4',
  Z: '\ud835\udcb5',
};
var keys = {
  0: '\u19b2',
  1: '\u19d2',
  2: '\u14bf',
  3: '\u15f1',
  4: '\u1530',
  5: '\u01bc',
  6: '\u1986',
  7: '\u14a3',
  8: '\u1c96',
  9: '\u1066',
  a: '\uaa96',
  b: '\u1947',
  c: '\u1974',
  d: '\u1994',
  e: '\uaac0',
  f: '\u183b',
  g: '\u19c1',
  h: '\uaadd',
  i: '\ua838',
  j: '\ua839',
  k: '\u16d5',
  l: '\uaab6',
  m: '\uaa91',
  n: '\uaa80',
  o: '\uaaae',
  p: '\u03c1',
  q: '\uaa87',
  r: '\u1945',
  s: '\u1993',
  t: '\uaabb',
  u: '\uaa8a',
  v: '\uaa9c',
  w: '\u1b59',
  x: '\u194a',
  y: '\uaa97',
  z: '\u01ba',
  A: '\uaa96',
  B: '\u1947',
  C: '\u1974',
  D: '\u1994',
  E: '\uaac0',
  F: '\u183b',
  G: '\u19c1',
  H: '\uaadd',
  I: '\ua838',
  J: '\ua839',
  K: '\u16d5',
  L: '\uaab6',
  M: '\uaa91',
  N: '\uaa80',
  O: '\uaaae',
  P: '\u03c1',
  Q: '\uaa87',
  R: '\u1945',
  S: '\u1993',
  T: '\uaabb',
  U: '\uaa8a',
  V: '\uaa9c',
  W: '\u1b59',
  X: '\u194a',
  Y: '\uaa97',
  Z: '\u01ba',
};
var CHAR_MAP = {
  0: '\ud800\udc24',
  1: '\ud800\udfd1',
  2: '\u01bb',
  3: '\u0a69',
  4: '\u152f',
  5: '5',
  6: '\ua626',
  7: '\ud802\udd19',
  8: '\ud800\udd3a',
  9: '\u1c59',
  A: '\u023a',
  B: '\u0243',
  C: '\u023b',
  D: '\u0110',
  E: '\u0246',
  F: '\u04fa',
  G: '\u20b2',
  H: '\u0126',
  I: '\u012a',
  J: '\u0248',
  K: '\u049e',
  L: '\u0141',
  M: '\u16d7',
  N: '\ua7a4',
  O: '\ua74a',
  P: '\ua750',
  Q: '\ua756',
  R: '\ua7a6',
  S: '\ua7a8',
  T: '\u023e',
  U: '\u0244',
  V: '\ua75e',
  W: '\u2c72',
  X: '\u04fe',
  Y: '\u024e',
  Z: '\u01b5',
  a: '\u2c65',
  b: '\u0180',
  c: '\u023c',
  d: '\u0111',
  e: '\u0113',
  f: '\u04fb',
  g: '\ua7a1',
  h: '\u0127',
  i: '\u012b',
  j: '\u0249',
  k: '\u049f',
  l: '\u0142',
  m: '\u1d6f',
  n: '\ua7a5',
  o: '\ua74b',
  p: '\ua751',
  q: '\ua757',
  r: '\u024d',
  s: '\ua7a9',
  t: '\u2c66',
  u: '\u1d7e',
  v: '\ua75f',
  w: '\u2c73',
  x: '\u04ff',
  y: '\u024f',
  z: '\u01b6',
};
var hex2binary = {
  0: '\ua74a',
  1: '\u16d1',
  2: '\u1616',
  3: '\u1663',
  4: '\u1526',
  5: '\u1515',
  6: '\u1473',
  7: '\u14a3',
  8: '\u10d6',
  9: '\u1597',
  A: '\ud800\udf00',
  B: '\ud800\udf01',
  C: '\ud800\udf02',
  D: '\ud800\udf03',
  E: '\ud800\udf04',
  F: '\ud800\udf05',
  G: '\u13f5',
  H: '\ud800\udec5',
  I: '\ud800\udf09',
  J: '\u13dd',
  K: '\ud800\udf0a',
  L: '\ud800\udf0b',
  M: '\ud800\udf0c',
  N: '\ud800\udf0d',
  O: '\ua74a',
  P: '\ud800\udf10',
  Q: '\ud800\udf12',
  R: '\ud800\udf13',
  S: '\ud800\udf14',
  T: '\ud800\udf15',
  U: '\ud800\udf35',
  V: '\u1553',
  W: '\u13d4',
  X: '\ud800\udec4',
  Y: '\ud800\udf19',
  Z: '\u2c7f',
  a: '\ud800\udf00',
  b: '\ud800\udf01',
  c: '\ud800\udf02',
  d: '\ud800\udf03',
  e: '\ud800\udf04',
  f: '\ud800\udf05',
  g: '\u13f5',
  h: '\ud800\udec5',
  i: '\ud800\udf09',
  j: '\u13dd',
  k: '\ud800\udf0a',
  l: '\ud800\udf0b',
  m: '\ud800\udf0c',
  n: '\ud800\udf0d',
  o: '\ua74a',
  p: '\ud800\udf10',
  q: '\ud800\udf12',
  r: '\ud800\udf13',
  s: '\ud800\udf14',
  t: '\ud800\udf15',
  u: '\ud800\udf35',
  v: '\u1553',
  w: '\u13d4',
  x: '\ud800\udec4',
  y: '\ud800\udf19',
  z: '\u2c7f',
};
/** @type {!Array} */
var expected = [
  E,
  B,
  {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    a: '\u5342',
    b: '\u4e43',
    c: '\u531a',
    d: '\u15ea',
    e: '\u4e47',
    f: '\u5343',
    g: '\u13b6',
    h: '\u5344',
    i: '\u4e28',
    j: '\uff8c',
    k: '\u049c',
    l: '\u3125',
    m: '\u722a',
    n: '\u51e0',
    o: '\u3116',
    p: '\u5369',
    q: '\u024a',
    r: '\u5c3a',
    s: '\u4e02',
    t: '\u3112',
    u: '\u3129',
    v: '\u142f',
    w: '\u5c71',
    x: '\u4e42',
    y: '\u311a',
    z: '\u4e59',
    A: '\u5342',
    B: '\u4e43',
    C: '\u531a',
    D: '\u15ea',
    E: '\u4e47',
    F: '\u5343',
    G: '\u13b6',
    H: '\u5344',
    I: '\u4e28',
    J: '\uff8c',
    K: '\u049c',
    L: '\u3125',
    M: '\u722a',
    N: '\u51e0',
    O: '\u3116',
    P: '\u5369',
    Q: '\u024a',
    R: '\u5c3a',
    S: '\u4e02',
    T: '\u3112',
    U: '\u3129',
    V: '\u142f',
    W: '\u5c71',
    X: '\u4e42',
    Y: '\u311a',
    Z: '\u4e59',
  },
  {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    a: '\u20b3',
    b: '\u0e3f',
    c: '\u20b5',
    d: '\u0110',
    e: '\u0246',
    f: '\u20a3',
    g: '\u20b2',
    h: '\u2c67',
    i: '\u0142',
    j: 'J',
    k: '\u20ad',
    l: '\u2c60',
    m: '\u20a5',
    n: '\u20a6',
    o: '\u00d8',
    p: '\u20b1',
    q: 'Q',
    r: '\u2c64',
    s: '\u20b4',
    t: '\u20ae',
    u: '\u0244',
    v: 'V',
    w: '\u20a9',
    x: '\u04fe',
    y: '\u024e',
    z: '\u2c6b',
    A: '\u20b3',
    B: '\u0e3f',
    C: '\u20b5',
    D: '\u0110',
    E: '\u0246',
    F: '\u20a3',
    G: '\u20b2',
    H: '\u2c67',
    I: '\u0142',
    J: 'J',
    K: '\u20ad',
    L: '\u2c60',
    M: '\u20a5',
    N: '\u20a6',
    O: '\u00d8',
    P: '\u20b1',
    Q: 'Q',
    R: '\u2c64',
    S: '\u20b4',
    T: '\u20ae',
    U: '\u0244',
    V: 'V',
    W: '\u20a9',
    X: '\u04fe',
    Y: '\u024e',
    Z: '\u2c6b',
  },
  good,
  {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    a: '\u00e5',
    b: '\u00df',
    c: '\u00a2',
    d: '\u00d0',
    e: '\u00ea',
    f: '\u00a3',
    g: 'g',
    h: 'h',
    i: '\u00ef',
    j: 'j',
    k: 'k',
    l: 'l',
    m: 'm',
    n: '\u00f1',
    o: '\u00f0',
    p: '\u00fe',
    q: 'q',
    r: 'r',
    s: '\u00a7',
    t: '\u2020',
    u: '\u00b5',
    v: 'v',
    w: 'w',
    x: 'x',
    y: '\u00a5',
    z: 'z',
    A: '\u00c4',
    B: '\u00df',
    C: '\u00c7',
    D: '\u00d0',
    E: '\u00c8',
    F: '\u00a3',
    G: 'G',
    H: 'H',
    I: '\u00cc',
    J: 'J',
    K: 'K',
    L: 'L',
    M: 'M',
    N: '\u00f1',
    O: '\u00d6',
    P: '\u00fe',
    Q: 'Q',
    R: 'R',
    S: '\u00a7',
    T: '\u2020',
    U: '\u00da',
    V: 'V',
    W: 'W',
    X: '\u00d7',
    Y: '\u00a5',
    Z: 'Z',
  },
  error,
  b,
  ch,
  a,
  conversion,
  L,
];

const callback = (fontMap: Object, text: string) =>
  text
    .split('')
    .map((char) => fontMap[char] || char)
    .join('');

export const fontsMaps = {
  futureAlienCharMap: (context: string) => callback(lookupTable, context),
  // USAFlagText: (context: string) =>
  //   '\ud83c\uddfa\ud83c\uddf8 ' +
  //   callback(b, context) +
  //   ' \ud83c\uddfa\ud83c\uddf8',
  BubbleFonts: (context: string) => callback(setFnMap, context),
  BubbleFontsLight: (context: string) => callback(languages, context),
  invertedSquaresCharMap: (context: string) =>
    callback(cached_wifi_network_list, context),
  darkSquareCharMap: (context: string) => callback(liveLeakUrl, context),
  bentTextCharMapText: (context: string) => callback(regExpMap, context),
  NinjaText: (context: string) => processdirectorrule(context),
  // taiVietCharMap: (context: string) => callback(keys, context),
  oldItalicText: (context: string) => callback(hex2binary, context),
  JavaneseRerengganText: (context: string) => $(context),
  boldCursiveCharText: (context: string) => parse_topmembers(context),
  MagicalText: (context: string) => $$(context),
  RainbowText: (context: string) => nodeArray(context),
  GunText: (context: string) => processlocationtitle(context),
  neonCharMap: (context: string) => callback(good, context),
  christmasText: (context: string) => push(context, expected),
  boldCharMap: (context: string) => callback(uniforms, context),
  italicCharMap: (context: string) => callback(res, context),
  boldItalicCharMap: (context: string) => callback(patterns, context),
  monospaceCharMap: (context: string) => callback(db, context),
  upperAnglesCharMap: (context: string) => callback(colors, context),
  greekCharMap: (context: string) => callback(oneToThree, context),
  doubleStruckCharMap: (context: string) => callback(lookup, context),
  oldEnglishCharMap: (context: string) => callback(map, context),
  boldOldEnglishCharMap: (context: string) => callback(E, context),
  cursiveCharMap: (context: string) => callback(KeyCodes, context),
  cursiveLettersBold: (context: string) => callback(B, context),
  wideTextCharMap: (context: string) => callback(entities, context),
  subscriptCharMap: (context: string) => callback(HEX_NUMBER_CHARS, context),
  superscriptCharMap: (context: string) => callback(_PATTERNS, context),
  squiggleCharMap: (context: string) => callback(error, context),
  squiggle2CharMap: (context: string) => callback(b, context),
  squiggle3CharMap: (context: string) => callback(ch, context),
  squiggle4CharMap: (context: string) => callback(a, context),
  squiggle5CharMap: (context: string) => callback(conversion, context),
  squiggle6CharMap: (context: string) => callback(L, context),
  stinkyText: (context: string) => injectDirectionProxy(context),
  zagloText: (context: string) => template(context),
  arrowBelowText: (context: string) => build(context),
  crossAboveBelowText: (context: string) => re(context),
  powerText: (context: string) => selector(context),
  dotsAlongText: (context: string) => unescapeTemplateVars(context),
  starBelowText: (context: string) => getWatchExpression(context),
  weirdBoxText: (context: string) => _normaliseClassName(context),
  thickBlockFramedText: (context: string) => showSpacesOnString(context),
  diametricAngleFrameText: (context: string) => decodeSdpFileName(context),
  dotBoxText: (context: string) => escapeSearchQuery(context),
  roundDotBoxText: (context: string) => convertMask(context),
  angulerBoxText: (context: string) => fixHeaderName(context),
  substitutionBoxText: (context: string) => mergeStars(context),
};
