import * as React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import Copy from '../assets/images/copy.svg';
import Edit from '../assets/images/edit.svg';
import { Link } from 'gatsby';

export interface IFontCardProps {
  fontName?: string;
  original?: string;
  text?: string;
  fontId?: string;
}

export function FontCard({ original, text, fontName, fontId }: IFontCardProps) {
  return (
    <div className="flex overflow-hidden justify-between transition-shadow duration-200 bg-white rounded  group-card divide-x hover:shadow-my">
      <Link
        to={`/fonts/${fontId}`}
        state={{ text: original }}
        className="cursor-pointer items-stretch p-cust rounded-l  bg-none  hover:bg-gradient-to-r hover:from-my-blue hover:to-my-violet group-font max-w-full w-full overflow-hidden"
      >
        <div className="pl-cardpad pt-cardpad bg-white flex-col h-full rounded-l-9px pb-1">
          <div className="text-my-dividers group-font-hover:text-my-violet mb-15px text-base">
            {fontName}
          </div>
          <div
            className="text-big text-my-black overflow-x-auto scrollbar scrollbar-thumb-gray-200"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
      </Link>
      <div className="grid grid-cols-1 divide-y min-h-btnh h-full w-btnsm md:w-btn md:min-w-btn ">
        <Link
          to={`/editor`}
          state={{ text: text }}
          className="cursor-pointer p-cust rounded-tr  bg-none  hover:bg-gradient-to-r hover:from-my-blue hover:to-my-violet group"
        >
          <div className="pl-smbtnl bg-white flex-row h-full rounded-tr-9px inline-flex w-full space-x-3 items-center">
            <Edit className="group-hover:filter-violet" />
            <span className="text-base text-my-blue group-hover:text-my-violet hidden md:inline">
              Изменить
            </span>
          </div>
        </Link>
        <div className="cursor-pointer p-cust rounded-br bg-none  hover:bg-gradient-to-r hover:from-my-blue hover:to-my-violet group">
          <CopyToClipboard
            text={text}
            onCopy={() =>
              toast(`${text} скопирован`, {
                // eslint-disable-next-line @typescript-eslint/quotes
                closeButton: false,
              })
            }
          >
            <div className="pl-smbtnl bg-white flex-row h-full rounded-br-9px inline-flex w-full space-x-3 items-center">
              <Copy className="group-hover:filter-violet" />
              <span className="text-base text-my-blue group-hover:text-my-violet hidden md:inline">
                Копировать
              </span>
            </div>
          </CopyToClipboard>
        </div>
      </div>
      {/* <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" /> */}
    </div>
  );
}
